.add-option-form__container{
  .ant-form-item-label{
    width        : 120px;
    margin-right : 20px;
  }
  .required-validation{
    color       : var(--color-red-1);
    font-weight : bold;
  }
.add-option-tenor-container {

  max-height: 400px;
  overflow-y: auto;
  margin-right: 0px !important;
  overflow-x: hidden;

}
  .input-number{
    max-width:15%;
    width:130px
  }

  //Ant-design does not have any checkbox-button selection, so the below CSS is for making the existing checkbox into checkbox-buttons
  div#bespoke-checkbox-list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 6px;
    
    > label.ant-checkbox-wrapper {
      font-size: 14px;
      flex-basis: 33.333333%;
      align-items: center;
      margin: 0;
      width: auto;
      padding: 0 15px;
      height: 32px;
      line-height: 30px;
      border: 1px solid rgb(217, 217, 217);
      border-right-width: 0;
  
      > span:first-child {
        display: none;
      }
      > span:last-child {
        padding: 0;
      }
    }
  
    > label.ant-checkbox-wrapper-checked {
      background: #1890ff;
    }
    > label.ant-checkbox-wrapper-checked + label.ant-checkbox-wrapper {
      border-left-width: 0;
    }
  
    > label.ant-checkbox-wrapper:last-child {
      border-right-width: 1px;
    }
  }
  

  .bespoke-tenor-title{
    color:var(--color-white-1);
    margin-bottom: 5px;
    text-align: center;
    font-size: 16px;
  }

  .tenor-date{
    width:33.33% !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .tenors-date-carousel{
    width:100%;
  }

  .selected-button{
    margin-inline:20px;
    background-color : var(--color-blue-2);
    color:var(--color-white-1) !important;
  }

  .tenor__container{
    gap:10px;
    width:100%;
    margin-inline-end : 80px;
  }

  .ant-form-item{
    max-height:400px;
  }

  .group-of-button{
    margin-inline : 15px;
  }

  .ratio-and-strike{
    &__container{
      display:grid;
      grid-template-columns : repeat(4, 20%);
      gap:10px;
    }

    &__input-number{
      width:28%;
    }
  }

  .calc--fields{
    margin-top:10px;
  }

  .showCalc__container{
    .showCalc__radio-group{
      display:flex;
      flex-direction:column;
    }
    .ratio-and-strike__input-number{
      max-height:35px;
      height:35px;
    }
    .ant-form-item-control-input-content{
      display: grid;
      grid-template-columns: 130px repeat(4, 273.5px);
      gap:10px;
      grid-auto-rows : 30px;
    }
  }

  .radio-group__container{
    margin-top:10px;
  }

  .horizontal-scroll__container{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    // justify-content: space-between;
    overflow-x: auto;
    margin: 0px !important;
    .strike-single-field{
      display: flex;
      align-items: baseline;
      .ant-input-number{
        margin: 0px 5px 0px 0px;
      }
      .group-of-button{
        margin: 0px 20px 10px 0px;
        display: flex;
        justify-content: center;

      }
    }
    .ant-input-number{
      margin: 0px 10px 10px 0px ;
      min-width:120px;
    }
  }

  .ant-modal-footer{
    margin-top:30px;
  }
  .ant-form-item{
    margin:0;
  }
  .ant-divider{
    margin:5px;
  }
}

.ant-select-item-empty{
  color : var(--color-red-1);
}
