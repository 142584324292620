.header-with-icon__container{
  width:70%;
  display: flex;

  .copy-shorthand{
    color: var(--color-blue-2);
    font-size:15px;
    padding-left:10px;
  }
}
