.input-field-with-label{
  display:flex;

  .label{
    width: 150px;
    font-weight: bold;
  }

  .input-field-units{
    width:17%;
  }
}
