.ag-grid-dynamic-table__container{
  width:100%;
  height:90vh;
  display: grid;
  grid-gap:40px;
  padding-top: 20px;
  overflow-y: auto;
  justify-content:center;
  grid-template-columns: 43% 43%;
  grid-auto-rows: 45%;
  .active--gray-row{
    background-color : var(--color-gray-7);
  }

  .font-bold{
    font-weight: bold;
  }
  .gray-font{
    color : var(--color-gray-7);
  }

  .ag-header-active, .ag-column-hover{
    background-color: inherit;
  }
}
.team-run-readonly-table {
  .dropable-container{
    width: 100%;
    height: 100%;
    display: flex;
    overflow: auto;
    .ag-grid-dynamic-table__container{
      gap: 20px;
      flex-direction: row;
      display: flex;
      // overflow-y: auto;
      width:100%;
      min-width: fit-content;
      height: inherit;
      .table-box{ 
        min-width: 35vw;
      }
    }
  }
}
