
body {
  background-color: white !important;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ant-layout-content{
  padding-top:0 !important;
}

#content-root {
  height: 100vh;
}

.ant-layout {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-layout-header {
  background-color: white;
  border-bottom: 1px solid #f0f0f0 !important;
  padding: 0;
  background-color: white !important;
  /* padding-bottom: 10px; */
}


.border-none {
  border: none !important;
}

.ant-typography {
  margin-bottom: 0 !important;
}

.header-menu {
  justify-content: end !important;
  width: 100% !important;
}

.justify-content-end {
  justify-items: end;
}

.w-100 {
  width: 100% !important;
}

.ant-col.ant-form-item-label {
  text-align: left;
}

.add-option-form .ant-form-item-label {
  font-weight: bold;
}

.add-option-form .ant-input-number-affix-wrapper {
  width: 125px;
}

.ant-carousel .slick-prev,
.ant-carousel .slick-next,
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover {
  font-size: inherit;
  color: currentColor !important;
}

.ant-carousel .slick-prev svg,
.ant-carousel .slick-next svg,
.ant-carousel .slick-prev:hover svg,
.ant-carousel .slick-next:hover svg {
  height: 12px;
  width: 12px;
}

.slick-prev:before,
.slick-next:before {
  color: red !important;
}

.ant-carousel .slick-prev,
.ant-carousel .slick-prev:hover {
  /* left: 10px; */
  z-index: 2;
  color: black;
  opacity: 1 !important;
}

.ant-carousel .slick-next,
.ant-carousel .slick-next:hover {
  /* right: 10px; */
  z-index: 2;
  color: black;
}

/* .slick-slider span svg {
  color: blue !important;
  display: block;
} */

.slick-slider {
  display: block !important;
}


.ag-theme-alpine,
.ag-theme-alpine .ag-row {
  font-size: 11px !important;
}

/* .ant-carousel .slick-prev, .ant-carousel .slick-next { */
/*   top: 36% !important; */
/* } */

.ant-carousel .ant-radio-button-wrapper.flex-fill {
 font-size: 12px !important;
}
   

/* .ag-row.ag-row-level-0 {
  background-color: var(--color-black-1) !important;
}


.ag-row.ag-row-level-1 {
  background-color: var(--color-gray-2) !important;
}

.ag-row.ag-row-level-2 {
  background-color: var(--color-gray-3) !important;
} */

/* Selection colors */
/* .ag-row-selected.ag-row-level-0{
  background-color: var(--color-blue-5) !important;
}  

.ag-row-selected.ag-row-level-1{
    background-color: var(--color-blue-6) !important;
}
  
.ag-row-selected.ag-row-level-2{
    background-color: var(--color-blue-7) !important;  
} */



