.other-info__container{
  .ant-modal-content{
    min-height: 60vh;

  }
  .other-details--section{
    .avg-and-carry-fields{
      margin-top:15px;
      display:grid;
      grid-template-columns: 10% repeat(4, 20%);
      grid-gap:10px;
      
      &--title{
        display:block;
        margin:auto;
      }
    }
    // gap: 5px;
    .input-field-with-label{
      display: flex;
      
      flex-direction: column;
      // width:calc(100% / 5);
      
      .label{
        font-size:13px;
        // margin-right: 20px;
        margin-bottom: 10px;
      }
      .input-field-units{
        width : 100%;
        
      }
    }
  }

  &--legs-section{
    height:250px;
    overflow:auto;
    margin-bottom: 40px;
    .cell-value{
      margin-bottom: 15px;
    }
  }
    .legs--title{
      width:450px;
      text-align:center;
      padding-bottom:10px;
    }

  .add-space--sub-leg{
    margin-left:20px;
  }

  .leg-input{
    width:200%;
    color:var(--color-black-1);
  }

  .underlying_tenor{
    max-width: 100%;
    display: flex;
    flex: 0 0 75%;

    & > input {
      &:last-child{
        margin-left: 20px;
      }
    }
  }
}
.other-info--container--spin{
  height: 60vh;
}