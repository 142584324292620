:root{

  
  //Blue
  --color-blue-2 : #1890ff;
  --color-blue-3 : #40a9ff;
  --color-blue-5 : #1d39c4;
  --color-blue-6 : #416fdc;
  --color-blue-7 : #3995ff;

  //White
  --color-white-1 : #fff;

  //Black
  --color-black-1 : #000;

  //Gray
  --color-gray-1 : #d9d9d9;
  --color-gray-2 : rgb(89, 89, 89);
  --color-gray-3 : rgb(155, 160, 166);
  // pricer header color
  --color-gray-4 : #1c1c1c;
  --color-gray-5 : #161a20;
  --color-gray-7 : #929090;
  --color-gray-8 : #EFF2F5;

 --disabled-color: #606060;
  //light theme colors
  --color-gray-9 :  grey;
  --color-gray-10 : rgb(179, 179, 179);
  --color-gray-11 : rgb(205 205 205);
  --color-gray-12 : #222831;
  //
--border-color: #5c5c5c;
  //Red
  --color-red-1 : #e3242b;
  --color-red-2 : #FF4D4F;
  //expired option color
  --color-red-3 : #f5222d;
  //Green
  --color-green-1: #52C41A;
  --color-green-2: #2FFC18;

  //Yellow 
  --color-yellow-1 : #ffff00;
  --color-yellow-2 : #FFD700;

  //Magenta
  --color-magenta-1 : #FF00F7;

  //Font Sizes

  --font-size-10 : 10px;
  --font-size-11 : 11px;

  --dropdown-selected-color: gray;
  --primary-color:var(--color-black-1);
  --spread-row-color: #6c6c3b;
  --middle-cell-spread-row-color: #484893;
}


