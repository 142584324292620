.my-vol-surface {
  height: 100%;
  overflow: auto;
  transition: top 1s ease !important;

  .vol-surface-spin {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}
