.content_dark {
  .ag-row.ag-row-level-0 {
    background-color: var(--color-black-1);
  }

  .ag-row.ag-row-level-1 {
    background-color: var(--color-gray-2) !important;
  }

  .ag-row.ag-row-level-2 {
    background-color: var(--color-gray-3) !important;
  }

  ////Selection
  .ag-row-selected.ag-row.ag-row-level-0 {
    background-color: var(--color-blue-5) !important;
  }

  .ag-row-selected.ag-row.ag-row-level-1 {
    background-color: var(--color-blue-6) !important;
  }

  .ag-row-selected.ag-row.ag-row-level-2 {
    background-color: var(--color-blue-7) !important;
  }
  ////Selection

  //##########these are special rules for the pricer
  .ag-row.summary-row {
    background-color: var(--color-black-1);
  }

  .ag-row.leg-row {
    background-color: var(--color-gray-2) !important;
  }

  .ag-row.subleg-row {
    background-color: var(--color-gray-3) !important;
  }

  //########## these are special rules for the pricer
  /// selection
  .ag-row-selected.summary-row {
    background-color: var(--color-blue-5) !important;
  }

  .ag-row-selected.leg-row {
    background-color: var(--color-blue-6) !important;
  }

  .ant-input{
    background: var(--color-black-1) !important;
    color: var(--color-white-1);
    border: 1px solid var(--color-gray-2);
  }

  .ag-row-selected.subleg-row {
    background-color: var(--color-blue-7) !important;
  }


  .select-dark .ant-select-selector {
    background: var(--color-black-1) !important;
    color: var(--color-white-1);
}
  .ant-input-group-wrapper[disabled],
  .ant-modal-dark.ant-input-disabled,
  .ant-input-number-disabled,
  .ant-input-affix-wrapper-disabled,
 
  .ant-input[disabled],
  .ant-btn[disabled],
  .ant-input-light-disabled,
  .ant-input-number-disabled.ant-input-number-input,
  .ant-input-number-group-wrapper.ant-input-number-disabled.input-field-units,
 .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector  {
    background-color: var(--disabled-color) !important;
    color: var(--color-gray-11) !important;
    border: 1px solid var(--color-gray-1) !important;
  }

  .ant-input-number-dark, .team-run-member-input, .change-password-input {
    background-color: var(--color-black-1);
    color: var(--color-white-1);
    border: 1px solid var(--color-gray-2);
}

  .ant-input-number-group-addon {
  background-color: var(--color-black-1);
  color: var(--color-white-1);
  border: 1px solid var(--color-gray-2);
 }

  .action-buttons__container .ant-btn[disabled] {
    background-color: transparent !important;
    color: var(--color-gray-7) !important;
    border: 0px solid var(--color-gray-7) !important;
  }

}

  
.content_light {
  .ant-input-group-wrapper[disabled],
  .ant-input-disabled,
  .ant-input-number-disabled,
  .ant-select-disabled,
  .ant-input-affix-wrapper-disabled,
  .ant-input-number-disabled,
  .ant-input-number-group-wrapper.ant-input-number-disabled.input-field-units {
    background-color: #f5f5f5 !important;
    color: black !important;
  }
  .ag-row.summary-row,
  .ag-row.ag-row-level-0 {
    background-color: var(--color-white-1) !important;
  }
 .action-buttons__container .ant-btn[disabled] {
    background-color: transparent !important;
    color: var(--color-gray-7) !important;
    border: 0px solid var(--color-gray-7) !important;
  }
 

  .ag-row.subleg-row,
  .ag-row.ag-row-level-2 {
    background-color: darkgray !important;
  }

  .ag-row-selected.summary-row .action-buttons__container .button-icon {
    width: 32px;
    color: var(--color-white-1) !important;
    background-color: transparent;
    font-size: 15px;
    border: none;
    &:hover {
      color: var(--color-blue-7);
    }
  }

  .ag-row-selected.ag-row.ag-row-level-0
    .action-buttons__container
    .button-icon {
    width: 32px;
    color: var(--color-white-1) !important;
    background-color: transparent;
    font-size: 15px;
    border: none;
    &:hover {
      color: var(--color-blue-7) !important;
    }
  }

  .ag-row.leg-row,
  .ag-row.ag-row-level-1 {
    background-color: lightgray !important;
  }

  /// selection
  .ag-row-selected.summary-row,
  .ag-row-selected.ag-row-level-0,
  .ag-row-selected.ag-row.ag-row-level-0 {
    background-color: var(--color-blue-5) !important;
    color: var(--color-white-1);

    .ag-group-expanded,
    .ag-group-contracted {
      color: aliceblue !important;
    }
  }

  .team-run-table .ag-row-selected.ag-row.ag-row-level-0 {
    color: var(--color-white-1);
  }
  .ag-row-selected.leg-row,
  .ag-row-selected.ag-row-level-1,
  .ag-row-selected.ag-row.ag-row-level-1 {
    background-color: var(--color-blue-6) !important;
    color: var(--color-white-1);

    .ag-group-expanded,
    .ag-group-contracted {
      color: aliceblue !important;
    }
  }

  .ag-row-selected.subleg-row,
  .ag-row-selected.ag-row-level-2,
  .ag-row-selected.ag-row.ag-row-level-2 {
    background-color: var(--color-blue-7) !important;
    color: var(--color-white-1);
  }
}