.pricer-ag-grid__container_dark {
  height: 100%;
  .ant-btn {
    background: transparent;
    color: var(--color-white-1);
    &:focus {
      background: transparent;
    }
  }
  .level-color {
    &__disable {
      background-color: var(--color-gray-2) !important;
      cursor: not-allowed !important;
    }
    &__0 {
      background-color: var(--color-black-1);
    }

    &__1 {
      background-color: var(--color-gray-2);
    }

    &__2 {
      background-color: var(--color-gray-3);
    }
  }

  .ag-row-selected.level-color__0 {
    background-color: var(--color-blue-5);
  }

  .ag-row-selected.level-color__1 {
    background-color: var(--color-blue-6);
  }

  .ag-row-selected.level-color__2 {
    background-color: var(--color-blue-7);
  }
  .ag-row-selected.level-color__disable {
    background-color: var(--color-gray-2);
    cursor: not-allowed;
  }

  .ag-row-hover {
    background-color: var(--color-gray-2);
  }

  .header-right-border {
    border-right: 2px solid var(--color-gray-2);

    & > div:first-child {
      display: none;
    }
  }

  .ag-icon-tree-closed {
    transform: rotate(90deg);
  }

  .ag-group-expanded {
    transform: rotate(-180deg);
  }
}

.pricer-ag-grid__container {
  height: 100%;

  .level-color {
    &__disable {
      background-color: var(--color-gray-2) !important;
      cursor: not-allowed !important;
    }
    &__0 {
      background-color: var(--color-black-1);
    }

    &__1 {
      background-color: var(--color-gray-2);
    }

    &__2 {
      background-color: var(--color-gray-3);
    }
  }

  .ag-row-selected.level-color__0 {
    background-color: var(--color-blue-5);
  }

  .ag-row-selected.level-color__1 {
    background-color: var(--color-blue-6);
  }

  .ag-row-selected.level-color__2 {
    background-color: var(--color-blue-7);
  }

  .ag-row-selected.level-color__disable {
    background-color: var(--color-gray-2);
    cursor: not-allowed;
  }

  .ag-row-hover {
    background-color: var(--color-gray-2);
  }

  .header-right-border {
    border-right: 2px solid var(--color-gray-2);

    & > div:first-child {
      display: none;
    }
  }

  .ag-icon-tree-closed {
    transform: rotate(90deg);
  }

  .ag-group-expanded {
    transform: rotate(-180deg);
  }
}

.filter-buttons_container {
  height: 65px;
  align-items: center;
  position: fixed;
  flex-wrap: wrap;
  top: 0;
  display: flex;
  flex-direction: row-reverse;
  margin-left: 15px;
}

.action-button_container {
  align-items: center;
  justify-content: space-around;
  width: 100%;
  .rotate-import--icon {
    rotate: -180deg;
  }
}


.buttons-container {
  &,
  &__big {
    display: flex;
    gap: 0.5rem;
  }

  &__small {
    display: flex;
    gap: 0.25rem;
  }
}
