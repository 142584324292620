@import "variables";

//Show Arrow For Every Input Number Across The Whole Project
.ant-input-number-handler-wrap {
  opacity: 1;
}
#root{
  background-color: var(--color-black-1);
  background: var(--color-black-1);
}

.bg-white{
  background: var(--color-white-1);
}

.ant-btn-dark{
  background: transparent;
  color: var(--color-white-1);
  &:hover{
    background: transparent;
  }
}

.audit-trail{
  height:100%;

  .dropdownHidden{
    .ag-group-contracted {
      visibility: hidden !important;
    }
  }

  &__pagination{
    display: flex;
    justify-content: center;

    .anticon{
      display:block;
    }
  }
}

.plotely-dynamic-graph__container{
  margin-top: 20px;
  width:100%;
  height:100%;
  display: grid;
  grid-gap:1.25rem;
  overflow: auto;
  height: fit-content;
  justify-content:space-between;
  grid-template-columns: repeat(auto-fit,minmax(200px,640px));
  .ant-btn[disabled], .ant-btn[disabled]:active, .ant-btn[disabled]:focus, .ant-btn[disabled]:hover {
    border-color: var(--color-gray-3);
    background: var(--color-gray-7)!important;
  }
  
  .graph-HighchartsReact{
    width: 500px;
    margin-left: 10px;
  }
  @media only screen and (max-width: 1359px) {
    justify-content: center;
  }
  & > * {
    height: 400px;
  }
  
}


.dark-input{
  background: var(--color-black-1);
  color: var(--color-white-1);
}

.w-100{
  width: 100%;
}


.filled--data_rangePicker__background {
  background-color:var(--color-yellow-1) !important;
  .ant-picker{
    &-input > input {
      color: var(--color-black-1) !important;
    }
    &-suffix {
      color: var(--color-black-1) !important;
    }
    &-clear {
      background: transparent;
    }
  }
}



.selected-button{
  color: var(--color-blue-3)!important;
  border-color: var(--color-blue-3);
  // background: var(--color-black-1);
  &:focus{
    background: transparent;
  }
}

 .exist-warning .ant-select-item{
    color: var(--color-yellow-2) !important;
  }

.dropdown-dark {
  background-color: var(--color-black-1);
  border: 1px solid var(--color-gray-2);
  // color: var(--color-white-1);
  .ant-select-item {
    color: var(--color-white-1);
  }

 
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: var(--color-blue-2);
  }
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: var(--color-gray-2);
  }
  .ant-select-dropdown-empty {
    background-color: var(--color-black-1);
    border: 1px solid var(--color-gray-2);
  }
  .ant-empty-description {
    color: var(--color-white-1);
  }
}

.menu-dropdown-dark {
  .ant-dropdown-menu {
    background-color: var(--color-black-1);
    .ant-dropdown-menu-item {
      color: var(--color-white-1);
    }
    .ant-dropdown-menu-submenu-title {
      color: var(--color-white-1);
    }
    .anticon {
      color: var(--color-white-1);
    }
    .ant-dropdown-menu-item-active {
      background-color: var(--color-gray-2);
    }
    .ant-dropdown-menu-submenu-active {
      background-color: var(--color-gray-2);
    }
    .ant-dropdown-menu-submenu-title {
      &:hover {
        background-color: var(--color-gray-2);
      }
    }
  }
}

.ant-dropdown-menu-dark {
  background: var(--color-black-1);
  .ant-dropdown-menu {
    background: var(--color-black-1);
  }
  .ant-dropdown-menu-item {
    color: var(--color-white-1);
    background: var(--color-black-1);
  }
  .ant-dropdown-menu-item-active {
    &:hover {
      background-color: var(--color-gray-2);
    }
  }
}

.ant-modal-dark {
  .trader-input{
    background-color: var(--color-black-1);
    color: var(--color-white-1);
  }
  .ant-modal-content {
    background-color: var(--color-black-1);
    border: 1px solid var(--color-gray-2);
   
    .other-date-picker-dark {
      background-color: var(--color-black-1);
      .ant-picker-input>input {
        color: var(--color-white-1) !important;
      }
      
      .ant-picker-clear {
        background: transparent;
      }
    }

    button {
      color: var(--color-white-1);
    }
   
    .ant-modal-header {
      background: var(--color-black-1);
      .ant-modal-title {
        color: var(--color-white-1);
      }
      border-color: var(--color-gray-2);
    }
    .ant-modal-body {
      color: var(--color-white-1);

      .ant-form-item-label > label {
        color: var(--color-white-1);
      }
    
      .team-run-members__container{
        .label-with-filled-input__container{
          .input--label{
            color: var(--color-white-1);
          }
        }
      }
      .ant-input-number-group-addon {
        background: var(--color-black-1);
        background-color: var(--color-black-1);
        color: var(--color-white-1);
        border: 1px solid var(--color-gray-2);
      }
      .ant-input-group-addon {
        background: var(--color-black-1);
        background-color: var(--color-black-1);
        color: var(--color-white-1);
        border: 1px solid var(--color-gray-2);
      }
      .ant-input-number {
        color: var(--color-white-1);
        border: 1px solid var(--color-gray-2);
        background: var(--color-black-1);
        &:hover {
          border: 1px solid var(--color-blue-2);
        }
        .ant-input-number-handler-wrap {
          background: transparent;
        }
        .ant-input-number-handler-up-inner {
          color: var(--color-white-1);
        }
      }
      .ant-input-number-disabled {
        color: var(--color-gray-3);
        &:hover {
          border: 1px solid var(--color-gray-2) !important;
        }
      }

      .ant-input-number-focused {
        border: 1px solid var(--color-blue-7);
      }
      .ant-select-selector {
        background: transparent;
        border: 1px solid var(--color-gray-2);
        color: var(--color-white-1);
      }
      .ant-select-arrow {
        color: var(--color-white-1);
      }
      .anticon {
        color: var(--color-white-1);
      }
 
      .ant-carousel .slick-slider .anticon {
        color: var(--color-white-1) !important;
      }

      .add-option-form__container .tenor-date {
        background: var(--color-black-1);
        color: var(--color-white-1);
      }
      .ant-radio-group-solid
        .ant-radio-button-wrapper-checked:not(
          .ant-radio-button-wrapper-disabled
        ) {
        background: var(--color-blue-2);
        color: var(--color-white-1) !important;
      }
      .ant-form label {
        background: var(--color-black-1);
        color: var(--color-white-1);
      }
    }
    .ant-btn-default {
      background: transparent;
    }
    .ant-modal-footer {
      border-color: var(--color-gray-2);
    }
    .ant-btn-primary[disabled] {
      color: var(--color-gray-2);
    }
  }
}


.date-picker-dark {
  .ant-picker {
    background-color: var(--color-black-1);
    &-suffix {
      color: var(--color-white-1);
    }
    &::placeholder {
      color: var(--color-white-1);
    }
    input{
      color: var(--color-white-1);
    }
  }
}

.date-picker-dropdown-dark {
  .ant-picker-panel-container {
    background-color: var(--color-gray-2);
    .ant-picker-header {
      color: var(--color-white-1);
      button {
        color: var(--color-white-1);
      }
    }
    .ant-picker-cell-in-view.ant-picker-cell-in-range{
      color: var(--color-black-1) !important;
    }
    .ant-picker-body {
      th {
        color: var(--color-white-1);
      }
      tbody {
        .ant-picker-cell-in-view {
          color: var(--bs-gray-200);
        }
      }
    }
  }
  .ant-picker-time-panel-column>li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner{
    color: var(--color-white-1);
  }
}

.ag-group-value{
  width: 100%;
  .type-wrapper{
    display: flex;
    justify-content: space-around;
    align-items: center;
    .setting-dropdown{
      cursor: pointer;
      margin-left: auto;
      
    }
  }
}

.setting-dropdown-menu{
  border: 0.5px solid var(--color-gray-3);
  border-radius: 2px;
}

.ant-input-number-dark, .ant-input-number-group-addon{
  // background-color: var(--color-black-1);
  //  color: var(--color-white-1);
  .ant-input-number-handler-wrap{
    background-color: var(--color-black-1);
    .anticon{
      color: var(--color-white-1);
    }
  }
}
.ant-input-number-gray{
  width: 85px;
  .ant-input-number-handler-wrap{
    background-color: var(--color-gray-12);
    .anticon{
      color: var(--color-white-1);
    }
  }
}

.ant-input-number-dark-disabled{
  .ant-input-number-wrapper{
    .ant-input-number-group-addon{
      background-color: var(--color-black-1);
      color: var(--color-gray-11);
    }
  }
}






.action-buttons__container{
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 100%;


  .button-icon{
    width: 32px;
    color:var(--color-white-1);
    background-color: transparent;
    font-size:15px;
    border:none;
    &:hover{
      color: var(--color-blue-7);
    }
  }
  .button-light-theme{
    color:var(--color-black-1);
  }

  .my-dropdown{
    transition: all  .5s ease !important;
  }

  .button-icon-disabled, .button-icon__disabled{
    transition: all 0.1s ease !important;
    color: var(--color-gray-7);
    .anticon-menu{
      cursor: not-allowed;
    }
    &:hover{
      color: var(--color-gray-7);
    }
  }
  .ant-btn[disabled] {
      width: 32;
      color: var(--color-gray-7);
      background-color: transparent;
    }

  .action-buttons__dropdown{
    padding-bottom: 7px;
    width: 32px;
    justify-content: center;
  }
}

.action-button_container{
  // align-items: center;
  // justify-content:space-around;
  // width:100%;
  // margin-top: -4px;
  .rotate-import--icon{
    rotate:-180deg;
  }
}

.min-max-w-980{
  min-width: 980px;
  // max-width: 980px;
}

.h-400{
  height:400px
}

.graph-btn{
  background: var(--color-gray-12);
  color: var(--color-white-1);
  &:hover, &:focus{
    background: var(--color-gray-12);
  }
  
}

.ant-table-body{
  tr.ant-table-row:hover{
     .ant-table-tbody{
      td.ant-table-cell-row-hover{
        background: var(--color-gray-12);
      }
      }
    
  }
}

.mh-10{
  margin: 0px 10px;
}

.ant-table-tbody{
  background-color: var(--color-gray-12);
}

.ant-table.ant-table-small .ant-table-footer, .ant-table.ant-table-small .ant-table-tbody>tr>td, .ant-table.ant-table-small .ant-table-thead>tr>th, .ant-table.ant-table-small .ant-table-title, .ant-table.ant-table-small tfoot>tr>td, .ant-table.ant-table-small tfoot>tr>th{
  background: var(--color-gray-12);
  color: var(--color-white-1);
}

.main_control_table{
  width: 100%;
  overflow: auto;
  .control-input{
    min-width: 115px;
    max-width: 115px;
    .ant-col-24{
      height: 32px;

    }
  }
  .ant-table-content{
    .ant-table-thead > tr > th {
      min-width: 115px ;
      max-width: 215px ; 
      width: 115px !important;
    }
  }
  
}


// .ant-input-number-disabled .ant-input-number-input {
//   color: var(--color-gray-1);
// }

.ag-theme-balham-dark .ag-ltr .ag-label-align-right .ag-label {
    margin-left: 4px;
    color: white ;
}
.ag-theme-balham-dark .ag-icon-filter::before {
    content: "";

}
.ag-theme-balham-dark .ag-icon-menu::before {
    content: "\f114";
}

.ag-theme-balham  .ag-icon-filter::before {
    content: "";

}
.ag-theme-balham .ag-icon-menu::before {
    content: "\f114";
}
span.anticon.anticon-menu {
  cursor: pointer;
}
