.edit-trade-details {
  min-height: 50vh;
  .buy-sell-buttons {
    border-radius: 2px;
    overflow: hidden;
    margin-bottom: 15px;
    & > button {
      font-weight: 500;
      border-radius: 0px;
    }
  }

  .active-button {
    background-color: var(--color-blue-2) !important;
    color: var(--color-white-1);
  }
  .title {
    margin-top: 15px;
  }
  .trade-premium_container {
    display: flex;
    width: 50%;
    margin-bottom: 15px;
    align-items: center;
    p {
      margin-bottom: 0;
      min-width: 300px;
    }
    .ant-input-number {
      width: 120px;
    }
  }
  .trade-details__container {
    max-width: 100%;
    margin-top: 15px;
    overflow: auto;
    .trader-details {
      & > span {
        display: block;
      }

      display: grid;
      grid-gap: 25px;
      grid-template-columns: 1fr 35%;
      margin-bottom: 10px;

      .sell-or-buy {
        width: 50px;
        margin-right: 15px;
      }

      .label {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .input-number-label {
        display: flex;

        .input-number--field {
          width: 100%;
        }
      }

      &--first-row {
        margin-bottom: 15px;
        display: flex;
        gap: 30px;
        .trade-first {
          display: flex;
          gap: 20px;
          width: 50%;
          min-width: 50%;
          align-items: center;
          .ant-input-number {
            margin-left: auto;
          }
        }
        .tradeSecond {
          width: 50%;
          display: flex;
          align-items: center;
          gap: 20px;
          .sell-or-buy--without-label {
            min-width: 25px;
          }
        }
        .audit-drop-down {
          width: 90px;
        }

        .sell-or-buy__container {
          display: flex;

          .sell-or-buy--label {
            width: 35px;
            margin-right: 25px;
            display: flex;
            align-items: center;
            font-weight: 500;
          }
        }
        .sell-or-buy--label {
          width: 15px;
          margin-right: 25px;
          display: flex;
          align-items: center;
          font-weight: 500;
        }
        .label {
          display: flex;
          align-items: center;
        }
      }
    }
  }

  .edit-trader-input {
    width: 90px;
    min-width: 0px;
    max-width: 90px;
    margin-left: auto;
  }

  .edit-company-trader-input {
    width: 150px;
    min-width: 0px;
    max-width: 150px;
    margin-left: auto;
  }

  .edit-trade-underlying {
    padding-left: 8px;
    margin-left: 8px;
    width: 90px;
    align-self: center;
    min-width: 0;
    max-width: 90px;
    
  }
}

.edit-trade-loading{
  height: 40vh !important;
  width: 100% !important;
}