.not-found-container {
  width: 100%;
  height: 100%;
  color: white;
  text-align: center;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 30px;
  gap: 20px;
  background-size: cover;
  background-position: center;

  h4,
  h5,
  p {
    color: white;
    font-weight: 400;
    margin-bottom: 10px;
    
  }

  h4 {
    font-size: 5rem;
    margin-bottom: 10px;
  }
  h5 {
    font-size: 2rem;
  }

  p {
    margin-bottom: 30px;
    font-size: 1.5rem;
  }

  .not-found-button {
    margin-left: 30px;
    width: 300px;
    background-color: black;
    border: 1px solid white;
    color: white;
    padding: 10px;
    font-size: 1rem;
     font-weight: bold;
    height: 50px;
    border-radius: 1px;
  }

  .not-found-button:hover {
    background-color: #2b3c4a;
    color: white;
  }
}
