.header__controls{

  .ant-tabs-top > .ant-tabs-nav::before,.ant-menu-horizontal {
    border-bottom: none;
  }

  height: 65px;
  min-height: 65px;
  background-color: var(--color-white-1);
  display:flex;
  justify-content:flex-end;
  align-items: center;
  .header-tabs{
    max-width :737px;
  }

  .header--logo{
    display:flex;
    align-items: center;
    margin-right:10px;
  }

  .vertical--divider{
    border:1px solid var(--color-black-1);
    height:80%;
  }

  .ant-tabs-nav{
    margin:0 !important;
  }

  &--control-item{
    min-height: 90%;
    min-width:70px;
    width:fit-content;
    ul{
      height:50px;
    }
  }

  &--expand-to-full-screen-width{
    width:100vw;
    border-bottom:1px solid var(--color-gray-8);
  }

}
.header__controls_dark{

  .ant-tabs-top > .ant-tabs-nav::before,.ant-menu-horizontal {
    border-bottom: none;
  }

  height: 65px;
  min-height: 65px;
  display:flex;
  justify-content:flex-end;
  align-items: center;
  background-color: var(--color-black-1) !important;
  color: var(--color-white-1);
  .header-tabs{
    color: var(--color-white-1);
    max-width :737px;
  }
  .ant-menu.ant-menu-dark{
    background: transparent !important
  }

  .header--logo{
    display:flex;
    align-items: center;
    margin-right:10px;
    color: var(--color-white-1);
  }

  .vertical--divider{
    border:1px solid var(--color-white-1);
    height:80%;
  }

  .ant-tabs-nav{
    margin:0 !important;
  }

  &--control-item{
    min-height: 90%;
    min-width:70px;
    width:fit-content;
    ul{
      height:150px;
    }
  }

  &--expand-to-full-screen-width{
    width:100vw;
    border-bottom:1px solid var(--color-gray-8);
  }
  .ant-menu-horizontal{
    background-color: var(--color-black-1);;
  }
  

}


.ant-menu-dark{
  ul[id*="SubMenu-11"]{
    background: var(--color-gray-4) !important;
  }
}





