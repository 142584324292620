.content_dark{
    background-color:var(--color-black-1);
    color: var(--color-white-1);
    overflow: hidden; 
    .ant-btn:active, .ant-btn:focus, .ant-btn:hover {
        text-decoration: none;
        background: transparent;
    }
}
.content_light{
    background-color: var(--color-white-1);
}