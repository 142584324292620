.additional-data__container{
  width: 90vw !important;

  .ant-modal-body{
    height:70vh;
    padding:0;
    overflow:auto;
  }

  .input-and-label{
    display: flex;
    flex-direction: row;

    & > input {
      width: 120px;
    }
  }

  .ant-modal-footer{
    & > button:first-child{
      background-color:var(--color-blue-2);
      border-color: var(--color-blue-2);
      color: var(--color-white-1);
    }

    & > button:last-child{
      display: none;
    }
  }

  //Legs Design
  &__legs{
    height:43%;
    overflow: auto scroll;
    padding:24px;

  

    .header-name{
      & > div {
        text-align: center;
        margin-bottom : 15px;
        font-weight: 600;
      }
    }

    .cell-value {
      & > div {
        margin-bottom : 10px;
      }


      & > div:not(:first-child) {
        margin:auto;
        margin-bottom: 10px;
      }
    }
  }
  //Measurements Design
  &__measurements{
    display:flex;
    flex-direction:column;
    padding-inline:24px;

    .value-label{
      width:20%;
      margin-bottom : 15px;
      text-align: right;
      font-weight:500;
    }

    .input-field-with-label{
      display:flex;
      margin-bottom : 15px;

      .label{
        width: 150px;
        font-weight: bold;
      }

      & .input-field-units{
        width: auto !important;
      }

      .input-field-units{
       

        .ant-input-number-handler-wrap{
          display:none;
        }
      }
    }
  }

  .divider {
    margin-block : 16px;
  }
}
