.tenor-dropdown:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  border: none !important;
  padding: 0px !important;
  margin: 0px !important;
  width: 100% !important;
  border-radius: 0px !important;
  outline: none !important;
  font-size: var(--font-size-10) !important;
  border-color: red !important;

  .ant-select-selection-item {
    font-size: var(--font-size-11) !important;
  }
}

.tenor-dropdown {
  width: 100%;
  text-align: start;
  margin: 0px;
  padding: 0px !important;
  font-size: var(--font-size-10);
  border: none !important;
  outline: none;
  border-color: red;
}
