.update-option__container{
  overflow-y:auto;

  .align-label-data{
    &__container{
      .label-dark {
            margin-right: 10px;
            color: black !important;
        }

        .label-light {
            margin-right: 10px;
            color: white !important;
        }
      max-height:200px;
      margin-bottom:20px;
      display:flex;
      justify-content:space-between;
      overflow:auto;
    }
  }
 

  /* Footer Style Buttons */
  .footer{
    font-weight:bold;
  }
  /* End Of Footer Style Buttons */
}

.trader-input {
    &__container {
        .label-dark {
            margin-right: 10px;
            color: black !important;
        }

        .label-light {
            margin-right: 10px;
            color: white !important;
        }
    margin-top: 20px;
    max-height: 200px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: auto;
       gap:10px;
    
    .trader-input {
      width: 40%;
      align-items: end;
      height: 32px;
      border-radius: 0px;
      border: 1px solid var(--color-gray-2);
    }
  }
}
