.greek-drop-down__container{
  min-Width: 155px;
  padding: 0px !important;
  margin: 0px !important;
  //? Made Greeks DropDown Like Other Header Column Style In Ag Grid
  .ant-select-selector{
    font-size:var(--font-size-11);
    color:var(--ag-header-foreground-color, rgba(245, 245, 245, 0.64));

    .ant-select-selection-item{
      font-weight: 600;
    }
  }

  &.ant-select-open {
    .ant-select-arrow {
      rotate: -180deg;
      transition: all .1s linear;
    }
  }
}

.greek-column-cell{
  padding-left: 22px !important;
  
}

.greek-drop-down__container-light{
  .ant-select-selector{
    background-color: var(--color-white-1) !important;
  }

  .ant-select-arrow{
    color:var(--color-black-1);
  }

  
}
.greek-drop-down__container-dark{
  .ant-select-selector{
    background-color: var(--color-gray-4) !important;
  }

  .ant-select-arrow{
    color:var(--color-white-1);
  }
}
