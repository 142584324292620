.ag-header-cell.month-estimates {
  .ag-header-cell-label {
    justify-content: center;
  }
}

div.ag-header-group-cell-label {
  text-align: center !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

@media only screen and (min-width: 1420px) and (max-width: 1720px) {
  .graphs-container {
    max-width: 100% !important;
    min-width: 100% !important;
  }

  .mainVolSurfaceTable {
    max-width: 100% !important;
    min-width: 100% !important;
  }
  .vol-surface-graph {
    max-width: 25% !important;
    min-width: 25% !important;
  }
}

@media only screen and (min-width: 1720px) and (max-width: 1920px) {
  .graphs-container {
    max-width: 25% !important;
    min-width: 25% !important;
  }
  .mainVolSurfaceTable {
    max-width: 75% !important;
    min-width: 75% !important;
  }
  .vol-surface-graph {
    max-width: 100% !important;
    min-width: 100% !important;
  }
}

@media only screen and (min-width: 1900px) {
  .graphs-container {
    max-width: 50% !important;
    min-width: 50% !important;
  }

  .mainVolSurfaceTable {
    max-width: 50% !important;
    min-width: 50% !important;
  }

  .vol-surface-graph {
    max-width: 100% !important;
    min-width: 100% !important;
  }
}


.sticky .mainVolSurfaceTable .ag-header.ag-pivot-off {
  position: fixed !important;
  top: 65px !important;
  z-index: 10;
  width: fit-content;
  transition: top 1s ease !important;
}
