.select-dark{
    .ant-select-selector{
        background: var(--color-black-1)!important;
        color: var(--color-white-1);
    }
    .ant-select-clear{
        background: var(--color-black-1);
    }
    .anticon{
        color: var(--color-white-1);
    }
}