.pricer-filter-buttons__container_dark{
  .ant-picker{
    background-color: var(--color-black-1);
    &-suffix{
      color: var(--color-white-1);
    }
    &::placeholder{
      color: var(--color-white-1);
    }
  }

  .filled--data__background {
    background-color:var(--color-yellow-1) !important;
    .ant-picker{
      &-input > input {
        color: var(--color-black-1);
      }
      &-suffix {
        color: var(--color-black-1);
      }
      &-clear {
        background: transparent;
      }
    }
  }

  .clac-data{
    min-width:150px;
    margin-left:10px;
  }

  .pricer-filter-buttons__container__buttons {
    .ant-radio-group {
      .ant-radio-button-wrapper{
        background:var(--color-balck-1);
        color: var(--color-white-1);
      }
      .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
        color: var(--color-blue-2) ;
      }
    }
    button {
      background: var(--color-balck-1);
      color: var(--color-white-1);
      &:hover{
        color: var(--color-blue-3);
      }
    }
  }
}

.pricer-filter-buttons__container{
  .filled--data__background {
    background-color:var(--color-yellow-1);
   .ant-picker-clear{
      background: transparent;
    }
  }

  .clac-data{
    min-width:150px;
    margin-left:10px;
  }
}

.darkCalender{
  .ant-picker-panel-container{
    background-color: var(--color-gray-2);
    .ant-picker-header{
      color: var(--color-white-1);
      button{
        color: var(--color-white-1);
      }
    }
    .ant-picker-body{
      th{
        color: var(--color-white-1);
      }
      tbody{
         .ant-picker-cell-in-view {
          color: var(--bs-gray-200);
        }
      }
    }
  }
}
